import { render, staticRenderFns } from "./SearchHeader.vue?vue&type=template&id=209dca86&scoped=true&"
import script from "./SearchHeader.vue?vue&type=script&lang=ts&"
export * from "./SearchHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SearchHeader.vue?vue&type=style&index=0&id=209dca86&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209dca86",
  null
  
)

export default component.exports